
export const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

export const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

export const colorsCards = {
    0: "#86f793",
    1:"#86f7c2",
    2:"#86f0f7", 
    3:'#8693f7',
    4: "#caf786",
    5:"#f7dd86",
    6:"#f7a686",
    7:"#af86f7"
};



// sorteio constant
export const players = [
    {
      value: 1,
      label: '1 Participante por equipe'
    },
    {
      value: 2,
      label: '2 Participantes por equipe'
    },
    {
      value: 3,
      label: '3 Participantes por equipe'
    },
    {
      value: 4,
      label: '4 Participantes por equipe'
    },
    {
      value: 5,
      label: '5 Participantes por equipe'
    },
    {
      value: 6,
      label: '6 Participantes por equipe'
    },
    {
      value: 7,
      label: '7 Participantes por equipe'
    },
    {
      value: 8,
      label: '8 Participantes por equipe'
    },
    {
      value: 9,
      label: '9 Participantes por equipe'
    },
    {
      value: 10,
      label: '10 Participantes por equipe'
    },
    {
      value: 11,
      label: '11 Participantes por equipe'
    }
  ];