import { Button, Container, IconButton, List, ListItem, ListItemText, MenuItem, TextField, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Footer from "src/components/Footer";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageTitle from 'src/components/PageTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {blue, grey, colorsCards, players} from './constants';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SchoolIcon from '@mui/icons-material/School';

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@mui/material';

import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { useEffect, useState } from "react";

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

interface Team {
  id: string;
  maxPlayers: number;
  players: Array<string>;
}

function SorteioPage() {

  const [currentQtdParticipants, setCurrentQtdParticipants] = useState(6);
  const [currentList, setCurrentList] = useState([]); // Lista de jogadores
  const [qtdRows, setQtdRows] = useState<Number>(0);
  const [openListRemove, setOpenListRemove] = useState(true);

  const [teams, setTeams] = useState<Array<Team>>();
  const handleChangeList = (event) => {
    if (!event.target.value) return;

    let rows = event.target.value.split("\n");
    setCurrentList(rows);
    setQtdRows(rows.length);
  };

  const handleChangeQtdParticipants = (event) => {
    setCurrentQtdParticipants(event.target.value);
  };

  const handleSortear = () => {
    let qtdTeam = 0;
    let resto = 0;

    let teamsContainer: Array<Team> = [];

    if (!qtdRows && !currentQtdParticipants) {
      return;
    }

    if (typeof qtdRows === 'number' && typeof currentQtdParticipants === 'number') {

      resto = qtdRows % currentQtdParticipants;
      qtdTeam = Math.floor(qtdRows / currentQtdParticipants) + (resto > 0 ? 1 : 0);

      for (let i = 0; i < qtdTeam; i++) {
        teamsContainer.push({ id: 'team_' + i, maxPlayers: (i === (qtdTeam - 1) && resto > 0) ? resto : currentQtdParticipants, players: [] })
      }

      let aleatoryListNames = shuffleArray(currentList);
      let grupoIndex = 0;
      for (let i = 0; i < aleatoryListNames.length; i++) {
        if (!teamsContainer[grupoIndex].players || teamsContainer[grupoIndex].players.length < teamsContainer[grupoIndex].maxPlayers) {
          teamsContainer[grupoIndex]['players'].push(aleatoryListNames[i]);
        } else {
          grupoIndex++;
          teamsContainer[grupoIndex]['players'].push(aleatoryListNames[i]);
        }
      }
      setTeams(teamsContainer);
    }
  }

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const getColorHeaderCard = (index) => {
    return colorsCards[index];
  }

  const handleOpenRemoveList = () => {
    setOpenListRemove(false);
  }

  const handleRemoveAtrasadoList = (selectItem) => {
    const listItens = currentList.filter(item => item !== selectItem);
    setCurrentList(listItens);
    setQtdRows(listItens.length);
  }

  const handleBackTextList = () => {
    setOpenListRemove(true);
  }

  return (
    <>
      <Helmet>
        <title>Sorteio de Times</title>
      </Helmet>
      <PageTitleWrapper >
        <PageTitle
          heading="Sorteio de Times"
          subHeading="Define os times de forma aleatória para evitar 'panelinhas'."
        >
         <Button
            href="https://www.instagram.com/reel/C54VwoBp1V_/?utm_source=ig_web_button_share_sheet&igsh=MzRlODBiNWFlZA=="
            target="_blank"
            rel="noopener noreferrer"
            variant="contained"            
            size="small"
            startIcon={<SchoolIcon fontSize="small" />}>
              Como usar?
          </Button>
          <Button
            href="https://drive.google.com/file/d/181PMA28ShINCqON4OrJs3KtbojooKjF7/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            variant="outlined"
            size="small"
            startIcon={<PictureAsPdfIcon fontSize="small" />}>
              QRCode
          </Button>
        </PageTitle>
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title="" />
              <Divider />
              <CardContent>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
                >
                  <TextField
                    id="outlined-select-currency"
                    select
                    sx={{ mb: 2 }}
                    label="Participantes por equipe"
                    value={currentQtdParticipants}
                    onChange={handleChangeQtdParticipants}
                  >
                    {players.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {!!openListRemove ?
                    <>
                      <Typography fontWeight={'bold'} sx={{ mb: 2}}><span style={{color:"red"}}>* </span>Não esqueça de adicionar apenas um peladeiro por linha</Typography>
                      <Textarea
                        aria-label="minimum height"
                        minRows={16}
                        placeholder="Adicione a lista de peladeiros"
                        sx={{ mb: 2 }}
                        onChange={handleChangeList}
                      />
                    </> :
                    <List dense={true}>
                      {!!currentList && currentList.map((item, index) => (
                        <ListItem key={item + "_" + index}
                          secondaryAction={
                            <IconButton key={"button_" + item + "_" + index} edge="end" aria-label="delete" color="error" onClick={() => handleRemoveAtrasadoList(item)}>
                              <DeleteIcon />
                            </IconButton>
                          }
                        >
                          <ListItemText key={"rext_" + item + "_" + index}
                            primary={item}
                          />
                        </ListItem>

                      ))}
                    </List>
                  }
                  <Grid
                    display="flex"
                    gap={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                  >
                    {!!openListRemove ? <Button
                      href={""}
                      rel="noopener noreferrer"
                      variant="outlined"
                      color="secondary"
                      onClick={handleOpenRemoveList}
                      startIcon={<DeleteIcon />}
                      disabled={!!(currentList.length <= 0) }
                      sx={{
                        width: '50%'
                      }}
                    >
                     Atrasados 
                    </Button> :
                      <Button
                        href={""}
                        rel="noopener noreferrer"
                        variant="outlined"
                        color="secondary"
                        onClick={handleBackTextList}
                        startIcon={<ArrowBackIosNewIcon />}
                        sx={{
                          width: '40%'
                        }}
                      >
                        Voltar
                      </Button>
                    }
                    <Button
                      href={"#time_0"}
                      rel="noopener noreferrer"
                      variant="contained"
                      onClick={handleSortear}
                      sx={{
                        width: '100%'
                      }}
                    >
                      Sortear times
                    </Button>

                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {teams &&
              teams.map((team, index) => {
                return < Card key={team.id} sx={{ my: 2 }} id={"time_" + index}>
                  <CardHeader sx={{ backgroundColor: getColorHeaderCard(index) }} title={"TIME " + (index + 1)} />
                  <Divider />
                  <CardContent>
                    {team.players && team.players.map((player, index) => {
                      return <Typography variant="h4" gutterBottom key={"player_" + index}>
                        {player}
                      </Typography>
                    })
                    }
                  </CardContent>
                </Card>
              })

            }

          </Grid>
        </Grid>
      </Container >
      <Footer />
    </>
  );
}

export default SorteioPage;
