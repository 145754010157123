import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Grid, Container } from '@mui/material';

interface PageTitleProps {
  heading?: string;
  subHeading?: string;
  docs?: string;
  children?: ReactNode;
}

const PageTitle: FC<PageTitleProps> = ({
  children,
  heading = '',
  subHeading = '',
  docs = '',
  ...rest
}) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          {heading}
        </Typography>
        <Typography variant="subtitle2">{subHeading}</Typography>
      </Grid>
      <Grid item>
        <Container sx={{display:'flex', gap:'5px'}}>{children}</Container>
      </Grid>

    </Grid>
  );
};

PageTitle.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  docs: PropTypes.string
};

export default PageTitle;
